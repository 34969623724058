import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

@ViewChild('home')home: ElementRef;
@ViewChild('header')header: ElementRef;

x = 0;
y = 0;

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      this.x = window.scrollY;
      this.y = this.home.nativeElement.firstChild.scrollHeight;
    }, 100);

    document.addEventListener('scroll', (event) => {
      this.x = window.scrollY;
      console.log(this.home.nativeElement.firstChild.scrollHeight);
    });
  }







}
