<div class="row p-0 m-0 g-0 justify-content-center">
  <div class="col-11 col-sm-10 card">
    <div class="row py-3">
      <div class="col-12 px-5">
        <h3 style="font-size:max(1.8vw, 12px)">FORMULARZ KONTAKTOWY</h3>
      </div>
    </div>
    <form class="row" [formGroup]="messageForm">

      <div *ngFor="let control of controls;index as i" class="col-12">
        <div class="row px-sm-2 py-1 align-items-center">
          <div class="col-12 px-sm-4 col-sm-4">
            <div *ngIf="this[control.name]?.invalid&&sendd" style="color:red; font-weight: 400; font-style: italic; font-size:max(1.2vw, 12px)">Pole wymagane</div>
            <label for="name" style="font-size:max(1.5vw, 12px)" class="labels">{{control.text}}</label>
          </div>
          <div class="col-12 col-sm-4">
            <input name="name" type="text" [formControlName]="control.name" class="form-control form-control-sm">
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row px-sm-2 py-3 align-items-center">
          <div class="col-12 px-sm-4 col-sm-4"><label for="tresc" class="labels" style="font-size:max(1.5vw, 12px)">Wiadomość</label></div>
          <div class="col-12 col-sm-4">
            <textarea class="w-100 w-sm-75"  style=" min-height:200px;border-radius: 5px" name="tresc" type="text" formControlName="message"></textarea>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row align-items-center px-sm-2">
          <div class="col-4 px-sm-2 pb-4">
            <label for="download"  style="font-size:max(1.5vw, 12px)" class="labels px-sm-2">Dodaj plik</label>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-12 py-3">
                <div class="row">
                  <div class="col-12"><button type="button" class="btn btn-info" (click)="dodajZal()" style="font-size:max(12px, 1.2vw)">Dodaj załącznik</button></div>
                  <div class="col-12 pt-2"><p style ="font-style: italic;font-size:max(1.5vw, 12px)">maksymalny rozmiar pliku 4 MB</p></div>
                </div>
                <input  #inpp class="custom-file-inputt" name="download" type="file" (change)="view($event.target.files)">
              </div>
              <div class="col-12" *ngFor="let filee of files;i as index">
                <div class="row">
                  <div class="col-10"><p style="color:rgb(165, 42, 42)">{{filee}}<span style="cursor: pointer" class="badge badge-danger"  (click)="delete(i)">Usuń</span></p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 py-5">
        <div class="row">
          <div class="col-12">
            <div *ngIf="zgoda.invalid&&sendd" style="color:red; font-weight: 400; font-style: italic;font-size:max(1.2vw, 12px)">Pole wymagane</div>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="zgoda; context: {messageForm: messageForm}"></ng-container>

        <div class="row">
          <div class="col-1"></div>
          <div class="col-11">
          </div>
        </div>
      </div>
      <div class="col-12 py-3 py-sm-5">
        <div class="row justify-content-end">
          <div class="col-8 col-sm-6">
            <button  type="button" class="btn btn-primary" (click)="send()" style="font-size:max(1.3vw, 12px)">Wyślij wiadomość</button>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12">
        <p style="font-size:max(1.4vw, 12px)">*pola wymagane</p>
      </div>
    </div>
    <div class="row">
      <div class="col-9"></div>
      <div class="col-3"></div>
    </div>
  </div>
</div>


<ng-template #zgoda  let-form="messageForm">
  <ng-container [formGroup]="messageForm">
    <div class="row justify-content-center">
      <div class="col-12" *ngIf="zgodaa.invalid&&sendd" style="color:red; font-weight: 400; font-style: italic; font-size:max(1.3vw, 12px)">Pole wymagane</div>
      <div class="col-8 col-sm-1 py-4">
        <div class="row justify-content-start justify-content-sm-center align-items-center h-100">
          <input class="col-5 col-sm" style="margin: auto;" type="checkbox" class="form-check-input"  formControlName="zgoda">
        </div>
      </div>

      <div class="col-12 col-sm-11">
        <p [style.color]="zgodaa.invalid&&sendd?'red':'black'" style="text-indent: 2vw; font-style: italic; font-size:max(12px, 1vw)">Wyrażam zgodę na przetwarzanie przez MITRO  Sp. z o.o.  moich danych osobowych, w szczególności mojego imienia, nazwiska, nazwy firmy, adresu e-mail, numeru telefonu w celach nawiązania ze mną kontaktu związanego z tematem mojego zgłoszenia. Podane przeze mnie dane będą przechowywane przez niezbędny do udzielenia odpowiedzi czas lub do momentu ustania dalszego kontaktu. Przyjmuję do wiadomości, iż mam prawo do przenoszenia danych lub ich sprostowania. Moja zgoda jest dobrowolna i może być wycofana w każdym czasie. Wszelkie pytania z tym związane mogę kierować na adres: mitro150@interia.pl
        </p>

      </div>
    </div>
  </ng-container>
</ng-template>
