<div #home class="container-fluid p-0 m-0 g-0 app__container">
  <app-header #header [x]="x" class="row fixed-top p-0 m-0 g-0"  style="background-color: #ffffff"></app-header>
  <div [style.margin-top.px]="y"></div>
  <div class="row pt-0 mt-0 m-0  g-0 content-container align-items-end justify-content-end">
    <!--<ng-container *ngTemplateOutlet="galery"></ng-container>-->
    <section class="col-12 px-0 py-0 m-0 g-0">
      <div class="row p-0 m-0 g-0">
        <app-article class="col-12 py-0 m-0 g-0"></app-article>
      </div>
    </section>
    <section class="col-12 px-0 py-5 m-0 g-0">
      <div class="row p-0 m-0 g-0">
        <app-contact class="col-12 px-0 pb-5 m-0 g-0"></app-contact>
        <app-form-mitro class="col-12 p-0 m-0 g-0"></app-form-mitro>
      </div>
    </section>
    <section class="col-12">
      <app-loga-firm></app-loga-firm>
    </section>
    <app-footer class="col-12 p-0 m-0 g-0"></app-footer>
  </div>
</div>


<ng-template #galery>
  <section class="col-12 p-0 pt-5">
    <div class="row justify-content-center row g-0 p-0 m-0">
      <div class="col-12 carousel slide" id="carouselExampleIndicators" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row g-0 p-0 m-0">
              <div class="col d-inline-block"><img class="w-100" src="./../../assets/klima.png" alt=""></div>
              <div class="col d-inline-block"><img class="w-100"  src="./../../assets/dom.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100"  src="./../../assets/dom 2.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/cisnienie.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/podlogowka.png" alt=""></div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row g-0 p-0 m-0">
              <div class="col d-inline-block"><img class="w-100"  src="./../../assets/dom 2.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/cisnienie.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/podlogowka.png" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/elektryka.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/skrzynka.jpg" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
