<div class="row px-1 px-sm-5 pt-5 pb-0">
  <div class="col-9 px-5"><h4 style="font-size: max(15px, 2.2vw)">ŚWIADCZONE USŁUGI:</h4></div>
  <div class="col-12 pt-5">
    <div class="row">
      <div class="col-6"><h4 class="d-none d-sm-block" style="text-align: center; font-size: max(12px, 2.2vw)">FIRMY</h4></div>
      <div class="col-6"><h4 class="d-none d-sm-block" style="text-align: center; font-size: max(12px, 2.2vw)">KLIENCI INDYWIDUALNI</h4></div>
    </div>
    <div class="row px-0 m-0 g-0  py-2 py-sm-5">
      <div class="col-12 col-sm-6 px-1 px-sm-2">
        <h4 class="d-sm-none" style="text-align: center; font-size: max(12px, 2.2vw)">FIRMY</h4>
        <ul>
          <li><p>Kompleksowe instalacje w istniejących obiektach i powstających.</p> </li>
          <li><p>Ogrzewanie hal i magazynów.</p> </li>
          <li><p>Systemy klimatyzacji.</p> </li>
          <li><p>Usługi spawalnicze.</p> </li>
        </ul>
      </div>

      <div class="col-12 col-sm-6 px-1 px-sm-2  py-5 py-sm-0">
        <h4 class="d-sm-none" style="text-align: center; font-size: max(12px, 2.2vw)">KLIENCI INDYWIDUALNI</h4>
        <ul>
          <li><p>Budowa i modernizacja instalacji gazowych,
            centralnego ogrzewania, wodociągowych,
            kanalizacyjnych.</p></li>
          <li><p>Kompleksowa budowa i modernizacja domów.</p></li>
          <li><p>Optymalizacja pracy kotłów gazowych –
            obniżenie kosztów utrzymania.</p></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row p-0 m-0 g-0">

</div>
<div class="row py-sm-5 py-2 px-0 m-0 g-0 justify-content-center">
  <div class="col-10">
    <div class="row align-items-center py-5  justify-content-center">
      <div class="col-sm-7 col-12 py-sm-5 py-2 px-2 px-sm-5">
        <h3 style="text-align:center; font-size:max(2.2vw, 15px)" >Program czyste powietrze</h3>
      </div>
      <div class="col-sm-3 col-8">
        <img class="w-100" src="./../../assets/KV-logo.png" alt="">
      </div>
    </div>
  </div>
  <div class="col-12 p-1 p-sm-0 col-sm-10">
    <p style="text-indent: 2vw">
      <span style="font-weight: bold">Czy wiesz,</span>
       że na wymianę starego kotła, wymianę okien, termomodernizację możesz dostać dotację?
    </p>
    <p style="text-indent: 2vw">Program <span style="font-weight: bold">„Czyste Powietrze”</span>  to do <span style="font-weight: bold">30.000</span>  dla podstawowego poziomu dofinansowania i
      <span style="font-weight: bold">do 69.000</span>
      dla najwyższego poziomu dofinansowania.</p>
    <p style="text-indent: 2vw">Tutaj dowiesz się więcej: <a style="font-style: italic" href="https://czystepowietrze.gov.pl" target="_blanc">Program Czyste Powietrze</a></p>
    <p style="text-indent: 2vw">Zapytaj nas o wymagania sprzętowe – dobierzemy i wykonany instalację dla Ciebie.</p>
    <p style="text-indent: 2vw"><span style="font-weight: bold">Pomagamy w wypełnianiu wniosków!</span> Masz czas do 31 grudnia 2027.</p>
    <p style="text-indent: 2vw">Pobierz ulotkę Programu: <a style="font-style: italic" href="https://mitro.com.pl/ulotka.pdf" target="_blank">Pobierz ulotkę</a></p>
  </div>
</div>





<!--<article class="articleContainer container-fluid g-0 m-0 p-0">-->
  <!--<ng-container *ngTemplateOutlet="title"></ng-container>-->
  <!--   <ng-container *ngTemplateOutlet="autoryzowanySerwis"></ng-container> -->
  <!--<app-article1></app-article1>-->
  <!--<app-article2></app-article2>-->
  <!--<ng-container *ngTemplateOutlet="chambers"></ng-container>-->
  <!--<app-article3></app-article3>-->
<!--</article>-->


<ng-template #title>
  <section class="container-fluid articleContainer__one">
    <div class="row  align-items-center title">
      <div class="col-12 col-sm-8 title__container">
        <h1  class="title__container--text"><strong >AUTORYZOWANY SERWIS TERMET</strong></h1>
      </div>
      <div class="col-12 col-sm-4 title__container--logo">
        <div class="containerLogo container-fluid">
          <a class="row" href="https://www.termet.com.pl" alt="logo termet" target="blank"  >
              <img class="col-6 col-sm-12" src="assets/images.png" alt="logo termet">
          </a>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #autoryzowanySerwis>
  <section  class="container-fluid articleContainer__two">
    <div class="row justify-content-center articleContainer__two--title">
        <h2>Atoryzowany Serwisant Gwarancyjny kotłów Termet w Białymstoku.</h2>
    </div>
    <div class="row articleContainer__two--text">
      <div class="col-sm-8 textWrapper">
        <p class="textWrapper__text">Termet jest producentem nowoczesnych rozwiązań w dziedzinie ogrzewania.
          Firma produkuje kotły kontensacyjne i standardowe, oraz urzadzenia pomocnicze, do wykorzystania w centralnym ogrzewaniu i dostarczania ciepłej wody użytkowej.
          Termet zapewnia standardową gwarancję na 24 miesiące. Warunkiem gwarancji jest zamontowanie urzadzenia przez Autoryzowanego Serwisanta Serwisanta i wykonanie Zerowego Uruchomienia urządzenia.
          Wydłużenie okresu gwarancyjnego do 5 lub 7 lat w przypadku montażu przez Autoryzowanego Serwisanta lub Instalatora oraz dokonywania regularnych przeglądow serwisowych.</p>
          <a class="textWrapper__link" href="https://www.termet.com.pl/" target="_blank" rel="noopener noreferrer"><p>Czytaj więcej...</p></a>
      </div>
      <div class="col-sm-4 hidden-sm-down">
        <app-aside  class="d-none d-sm-block"></app-aside>
      </div>
    </div>
  </section>
</ng-template>


<ng-template #czystePowietrze>

</ng-template>


<ng-template #chambers>
  <section class="container-fluid articleContainer__three">
    <div class="row articleContainer__three--title justify-content-center">
      <h2 class="mt-5 mb-3 px-3 px-sm-5 col-7" >Montaż kotłów dużej mocy</h2>
    </div>
    <div class="row articleContainer__three--text justify-content-center">
    <p class="px-3 tIdent col-10">Firma specjalizuje się w montażu i serwisie gazowych kotłów dużych mocy. Wykonuje kompletną instalację centralnego
       ogrzewania oraz zaopatrzenia w wodę dla przemysłu. Montaż armatury hydraulicznej. Wykonuje również usługi spawalnicze. </p>

      <p class="px-3 tIdent col-10">Firma prowadzi montaż instalacji ogrzewania w zakładach przemysłowych. Instalacje ogrzewania dla budynków
        użyteczności publicznej. Instalacje przeciwpożarowe. Prace montażowe urządzeń. Pełna instalacja hydrauliczna
         zakładów usługowych. </p>
    </div>


<app-uslugi></app-uslugi>
  </section>
</ng-template>






