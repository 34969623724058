
import { Component, OnInit, Input} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  @Input()x;

  get a(): boolean{return ((document.body.clientWidth < 576) && (document.body.clientWidth > 320)) ? true : false; } // = false;
  get b(): boolean{return (document.body.clientWidth <= 320) ? true : false; } // false;

  adresses = [
    'klima.png',
    'dom.jpg',
    'dom 2.jpg',
    'cisnienie.jpg',
    'podlogowka.png',
    'elektryka.jpg',
    'skrzynka.jpg'];




  constructor(private modalService: NgbModal) { }



  ngOnInit(): void {
  }


  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  onLoad(e): void{
    // this.f = e.target.height;
    // console.log('e', e.target.height);

  }



}
