<div class="row justify-content-center align-items-center my-5 ">
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://www.termet.com.pl/znajdz-serwis/11 ">
    <img src="./../../assets/termet.png" alt="" class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://www.grohe.pl/pl_pl/?target_group=inst ">
    <img src="./../../assets/grohe.png" alt=""  class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://sanplast.pl/ ">
    <img src="./../../assets/sanplast.png" alt=""  class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://magnaplast.pl/ ">
    <img src="./../../assets/magnaplast.png" alt=""  class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://kfa.pl/ ">
    <img src="./../../assets/logo_kfa.png" alt=""  class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://www.buderus.com/pl/pl/ ">
    <img src="./../../assets/buderus.jpg" alt=""  class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="http://www.refleks.pl/ ">
    <img src="./../../assets/logo.gif" alt="" class="img-fluid">
  </a></div>
  <div class="col-4 col-sm-3 my-3 col-md-1"><a href="https://www.vaillant.pl/klienci-indywidualni ">
    <img src="./../../assets/vaillant-logo-272x72-1888261.png" alt="" class="img-fluid">
  </a></div>
</div>
