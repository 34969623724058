<header class="col-12 p-0 m-0 g-0"  style="background-color: #ffffff">
  <div class="row p-0 m-0 g-0 align-items-center"  style="background-color: #ffffff">
    <div class="col-12 col-sm-6 p-0 m-0 g-0" style="background-color: #ffffff">
      <img src="./../../assets/Screenshot(46).png" alt="" class="logo img-fluid col-11"><!--LOGO-->
    </div>
    <ng-container *ngTemplateOutlet="nav" class="navClass"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="gallery" class="row m-0 p-0 "   style="background-color: #ffffff"></ng-container>
</header>

<ng-template #nav>
  <div class="col-12 hamburger">
    <div class="row justify-content-end align-items-center">
      <div class="col-8">
        <a href="tel:609 244 988" style="text-decoration: none; color:#3294fe" class=" nav1">
          <p style="text-align: center; font-size: max(12px, 1.5vw); color:#3294fe" class=" p-0 m-0 g-0 nav1">Tel: 609 244 988</p>
        </a>
      </div>
      <div class="col-3 mr-2">
        <img src="./../../assets/icons8-hamburger-menu-48.png" alt="hamburger menu" style="cursor: pointer" class="w-100" (click)="open(content)">
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 p-0 m-0 g-0 navClass"  style="background-color: #ffffff">
    <div class="row p-0 m-0 g-0 justify-content-center" style="background-color: #ffffff">
      <div class="col-11 p-0 m-0 g-0" style="background-color: #ffffff">
        <div class="row p-0 m-0 g-0 align-items-center"  style="background-color: #ffffff">
          <div class="col p-0 m-0 g-0">
            <a href="#kontakt" style="text-decoration: none; color:#0081be" class=" nav1">
              <p style="text-align: center; font-size: max(12px, 1.5vw); color:#0081be" class=" p-0 m-0 g-0 nav1">KONTAKT</p>
            </a>
          </div>
          <div routerLink="./Pobierz" class="col p-0 m-0 g-0" style="cursor: pointer; color: rgb(29, 26, 26)">
            <p style="text-align: center; font-size: max(12px, 1.5vw); color:#0081be" class=" p-0 m-0 g-0 nav1"> PLIKI DO POBRANIA</p>
          </div>
          <div class="col p-0 m-0 g-0" style="background-color: #ffffff">
            <a href="tel:609 244 988" style="text-decoration: none; color:#0081be" class=" nav1">
              <p style="text-align: center; font-size: max(12px, 1.5vw); color:#0081be" class=" p-0 m-0 g-0 nav1">Tel: 609 244 988</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #gallery>
  <section class="col-12 px-0 pb-2 g-0"  [ngClass]="{'pt-2':x > 200, 'pt-5':x <= 200}"  style="background-color: #ffffff">
    <div class="row justify-content-center row g-0 p-0 m-0">
      <div class=" carousel slide" [ngClass]="{'col-8':x > 200, 'col-12':x <= 200}" id="carouselExampleIndicators" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active"  *ngIf="!b">
            <div class="row g-0 p-0 m-0">
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75" src="./../../assets/klima.png" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/dom.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/dom 2.jpg" alt=""></div>
              <div class="col d-none d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/cisnienie.jpg" alt=""></div>
              <div class="col d-none d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/podlogowka.png" alt=""></div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="!b">
            <div class="row g-0 p-0 m-0">
              <div class="col d-none d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/dom 2.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/cisnienie.jpg" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/podlogowka.png" alt=""></div>
              <div class="col d-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/elektryka.jpg" alt=""></div>
              <div class="col d-none d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/skrzynka.jpg" alt=""></div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="a && !b">
            <div class="row g-0 p-0 m-0">
              <div class="col d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/dom 2.jpg" alt=""></div>
              <div class="col d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75" src="./../../assets/klima.png" alt=""></div>
              <div class="col d-sm-inline-block"><img class="w-100 p-sm-0 w-sm-75"  src="./../../assets/skrzynka.jpg" alt=""></div>
            </div>
          </div>
          <ng-container *ngIf="b">
            <div *ngFor="let adres of adresses;let first = first" class="carousel-item" [ngClass]="{'active': first}">
              <div class="row g-0 p-0 m-0 justify-content-center">
                <div class="col-8"><img class="w-100 p-sm-0 w-sm-75" [src]="'./../../assets/'+adres" alt=""></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header">
    <p routerLink="./Pobierz" style="color: #0081be; cursor: pointer"  (click)="modal.close('Save click')">PLIKI DO POBRANIA</p>
    <a href="#kontakt" style="text-decoration: none">
      <p style="color: #0081be; cursor: pointer;"  (click)="modal.close('Save click')">KONTAKT</p>
    </a>

  </div>

  <div class="modal-footer display-none">
  </div>
</ng-template>


