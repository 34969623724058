<div id="kontakt" class="row px-2 m-0 g-0 justify-content-center">
  <div class="col-12 col-sm-10 card px-3  px-sm-5">
    <h3 class="px-3 py-2" style="font-size:max(2.0vw, 12px)">KONTAKT</h3>
    <div class="row">
      <h2 class="col-12" style="font-size:max(1.6vw, 12px)"><span style="margin-right: .5vw">MITRO</span><span class="sp_z">Sp. z o.o.</span></h2>
      <h2 class="col-12" style="font-size:max(1.6vw, 12px)"><a style="color: black; text-decoration: none" href="tel:609 244 988"> Telefon: <span style="color: #0081be;">609 244 988 </span></a></h2>
      <h2 class="col-12" style="font-size:max(1.6vw, 12px)">E-mail: mitro150@interia.pl</h2>
      <h2 class="col-12 py-3 px-5" style="font-size:max(2.1vw, 12px)">Działamy na terenie całego kraju</h2>
    </div>
  </div>
</div>





