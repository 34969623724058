<div class="container">
  <div class="row py-5">
    <div class="col-3 col-md-1 col-sm-2 px-5 py-3" routerLink="/" style="cursor: pointer"  style="font-size: max(12px, 1vw)">
      <img class="mw-50 w-md-25" style="cursor: pointer" src="./../../assets/arrow-left-circle.png" alt="">
      <p style="font-size:max(2.2vw, 12px)" style="cursor: pointer">Powrót</p>
    </div>
  </div>

      <div class="row justify-content-start px-3 px-sm-5">
        <div class="col-12 px-5"><h2 style="font-size: max(12px, 3vw)">Pliki do pobrania:</h2></div>
        <div class="col-12">
          <div class="row py-3">
            <div class="col-6" style="font-size: max(12px, 1.5vw)"><p style="font-size:max(2.2vw, 12px)">Wniosek do pobrania</p></div>
            <div class="col-6"><a [href]="'./../../assets/deklaracja.pdf'" type="application/octet-stream" target="_blank"  style="font-size: max(12px, 2.2vw)">Pobierz plik</a></div>
          </div>
          <div class="row">
            <div class="col-6"><p style="font-size:max(2.2vw, 12px)">Ulotka</p></div>
            <div class="col-6"><a style="font-size:max(2.2vw, 12px)" href="https://mitro.com.pl/ulotka.pdf"  target="_blank" download>ulotka</a></div>
          </div>
        </div>
      </div>


</div>
