import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { endWith, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-form-mitro',
  templateUrl: './form-mitro.component.html',
  styleUrls: ['./form-mitro.component.scss']
})
export class FormMitroComponent implements OnInit, AfterViewInit {

@ViewChild('inpp')inp1: ElementRef;

files: any = [];
files1: any = [];

sendd = false;
disableInputs = false;



messageForm = new FormGroup({
  firstName: new FormControl('', Validators.required),
  lastName: new FormControl('', Validators.required),
  tel: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]{6,}') ]),
  email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  woj: new FormControl(''),
  message: new FormControl(''),
  zgoda: new FormControl(false, Validators.requiredTrue)
});

get firstName(): any { return this.messageForm.get('firstName'); }
get lastName(): any { return this.messageForm.get('lastName'); }
get tel(): any { return this.messageForm.get('tel'); }
get email(): any { return this.messageForm.get('email'); }
get zgodaa(): any { return this.messageForm.get('zgoda'); }

controls = [
  {text: 'Imię*', name: 'firstName'},
  {text: 'Nazwisko/Nazwa firmy*', name: 'lastName'},
  {text: 'Telefon*', name: 'tel'},
  {text: 'e-mail*', name: 'email'},
  {text: 'Województwo', name: 'woj'}];

  constructor(private http: HttpClient) { }



  ngOnInit(): void {
    this.messageForm.valueChanges.subscribe(a => {
      console.log('a', a);
    });
  }



  dodajZal(): void{
    this.inp1.nativeElement.click();
  }

  delete(i): void{
    this.files.splice(i, 1);
    this.files1.splice(i, 1);
  }

  ngAfterViewInit(): void{
// console.log('inp1', this.inp1);

  }








  send(): void{
    this.disableInputs = true;
    this.sendd = true;
    console.log(this.messageForm.status);

    if (this.messageForm.status === 'VALID'){
      this.sendd = false;
      const fd = new FormData();


      Array.from(this.files1).forEach((file: any) => {
          fd.append('download', file, file.name);
      });




      for (const key in this.messageForm.value) {
        if (Object.prototype.hasOwnProperty.call(this.messageForm.value, key)) {
          const mf = JSON.parse(JSON.stringify( this.messageForm.value));
          fd.append(key, mf[key]);
        }
      }

      this.http.post('/users', fd , {responseType: 'text'} ).pipe(tap((a) => {
        this.files1 = [];
        this.files = [];
        this.messageForm.reset({
          firstName: '',
          lastName: '',
          tel: '',
          email: '',
          woj: '',
          message: '',
          zgoda: false
        });
      }))
      .subscribe(
        {
          next(x): void {
             alert('Wiadomość została wysłana');
             this.disableInputs = true;
             },
          error(err): void {
            alert('Problem z wysłaniem spróbuj jeszcze raz');
            this.disableInputs = true;
           },
          complete(): void { }
        }
);
    }

    // this.http.get('/users', {responseType: 'text'}).subscribe(a => {alert(a); } );

  }

  view(e: any): void{


  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < e.length; i++) {

    this.files.push(e[i].name);
    this.files1.push(e[i]);

  }



  console.log('inprr', e);


  }

}


